exports.identityCardRequest = function() {
    $('#identity-card-request-form').submit(function (event) {
        const formData = new FormData(event.target);
        const requestIdentityCard = formData.get('identity-card');
        if (requestIdentityCard === 'yes') {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'Request EIC',
                eventAction: 'Yes',
            });
        }
        if (requestIdentityCard === 'no') {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'Request EIC',
                eventAction: 'No',
            });
        }
    });
};