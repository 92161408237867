exports.lastRegisteredToVote = function() {
    $('#last-registered-to-vote-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventHowLastRegistered(formData);
    });
};


function gaEventHowLastRegistered(formData) {
    const howLastRegistered = formData.get('how-last-registered');
    if (howLastRegistered === 'ordinary') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'How last registered',
            eventAction: 'UK resident',
        });
    }
    if (howLastRegistered === 'overseas') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'How last registered',
            eventAction: 'British or Irish citizen living abroad',
        });
    }
    if (howLastRegistered === 'forces') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'How last registered',
            eventAction: 'Member of the armed forces',
        });
    }
    if (howLastRegistered === 'crown') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'How last registered',
            eventAction: 'Crown servant',
        });
    }
    if (howLastRegistered === 'council') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'How last registered',
            eventAction: 'British Council employee',
        });
    }
    if (howLastRegistered === 'not-sure') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'How last registered',
            eventAction: 'Not sure',
        });
    }
}