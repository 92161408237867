const compact = require('lodash/compact');

exports.nationality = function nationality() {
    $('#add-additional-country-button').click(function() {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/nationality',
            eventAction: 'Add additional country click',
            eventLabel: 'Nationality - Add additional country clicked'
        });
    });
    $('#nationality-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventMultipleNationalities(formData);
        gaEventContactEmail(formData);
    })
};

function gaEventMultipleNationalities(formData) {
    const numAdditionalNationalities = compact([
        formData.get('added-country-1'),
        formData.get('added-country-2'),
        formData.get('added-country-3')
    ]).length;

    const numUkNationalities = formData.getAll('nationality').filter(nat => nat !== 'other').length;

    const numNationalities = numAdditionalNationalities + numUkNationalities;

    if (numNationalities > 1) {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/nationality',
            eventAction: 'Multiple nationalities provided',
        });
    }
}

function gaEventContactEmail(formData) {
    if (formData.get('contact-email')) {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Email Address Collection',
            eventAction: 'Nationality Email',
            eventLabel: 'Nationality - Email Address Provided'
        });
    }
}