exports.passport = function() {
    $('#passport-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventHasPassport(formData);
        gaEventBornInUk(formData);
    });
};

function gaEventHasPassport(formData) {
    const hasUkPassport = formData.get('has-uk-passport');
    if (hasUkPassport === "yes") {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Has UK passport',
            eventAction: 'yes'
        });
    }
    if (hasUkPassport === "no") {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Has UK passport',
            eventAction: 'no'
        });
    }
}

function gaEventBornInUk(formData) {
    const bornInUk = formData.get('born-inside-uk');
    if (bornInUk === "yes") {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Born inside UK',
            eventAction: 'yes'
        });
    }
    if (bornInUk === "no") {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Born inside UK',
            eventAction: 'no'
        });
    }
}