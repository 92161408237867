exports.addressFirstAnalytics = function() {
    $('#address-first-form').submit(function (event) {
        const formData = new FormData(event.target);
        const hasUkAddress = formData.get('has-uk-address');
        if (hasUkAddress === "resident") {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'Has UK address',
                eventAction: 'Yes',
                eventLabel: "Resident"
            });
        }
        if (hasUkAddress === 'not-resident') {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'Has UK address',
                eventAction: 'Yes',
                eventLabel: "Not resident"
            });
        }
        if (hasUkAddress === 'no-connection') {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'Has UK address',
                eventAction: 'No'
            });
        }
    });
};
