exports.waysToVote = function() {
    $('#way-to-vote-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventWayToVote(formData);
    });
};

function gaEventWayToVote(formData) {
    const wayToVote = formData.get('way-to-vote');
    if (wayToVote === "proxy") {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Way to vote',
            eventAction: 'Proxy',
        });
    }
    if (wayToVote === 'post') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Way to vote',
            eventAction: 'Post',
        });
    }
    if (wayToVote === 'in-person') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Way to vote',
            eventAction: 'In-person',
        });
    }
}