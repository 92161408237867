exports.otherAddress = function() {
    $('#other-address-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventHasOtherAddress(formData);
    });
};

function gaEventHasOtherAddress(formData) {
    const otherAddress = formData.get('other-address');
    if (otherAddress === "none") {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/other-address',
            eventAction: 'No',
            eventLabel: "No other address"
        });
    }
    if (otherAddress === 'secondHome') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/other-address',
            eventAction: 'Yes',
            eventLabel: "Has second home"
        });
    }
    if (otherAddress === 'student') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/other-address',
            eventAction: 'Yes',
            eventLabel: "Has student address"
        });
    }
}