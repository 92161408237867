exports.previousAddress = function() {
    $('#previous-address-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventHasPreviousAddress(formData);
    });
};

function gaEventHasPreviousAddress(formData) {
    const previousAddress = formData.get('previous-address');
    if (previousAddress === "no") {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/previous-address',
            eventAction: 'No',
            eventLabel: "No previous address"
        });
    }
    if (previousAddress === "yes-uk") {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/previous-address',
            eventAction: 'Yes',
            eventLabel: "Moved from UK address"
        });
    }
    if (previousAddress === "dont-know") {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/previous-address',
            eventAction: "Don't know",
            eventLabel: "Don't know if moved since last registration (NI)"
        });
    }
    if (previousAddress === "yes-abroad") {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/previous-address',
            eventAction: 'Yes',
            eventLabel: "Moved from abroad address"
        });
    }

}