exports.voteInPerson = function() {
    $('#vote-in-person-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventVoteInPerson(formData);
        gaEventReason(formData);
    });
};

function gaEventVoteInPerson(formData) {
    const requestProxy = formData.get('proxy-vote');
    if (requestProxy === 'yes') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Request postal or proxy vote (NI)',
            eventAction: 'Yes',
        });
    }
    if (requestProxy === 'no') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Request postal or proxy vote (NI)',
            eventAction: 'No',
        });
    }
}

function gaEventReason(formData) {
    const reason = formData.get('reason');
    if (reason === "medical") {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Reason for postal or proxy voting (NI)',
            eventAction: 'Medical'
        });
    }
    if (reason === 'educational') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Reason for postal or proxy voting (NI)',
            eventAction: 'Educational'
        });
    }
    if (reason === 'employment') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Reason for postal or proxy voting (NI)',
            eventAction: 'Employment'
        });
    }
}