exports.forcesStatement = function forcesStatement() {
    $('#forces-statement-form').submit(function (event) {
        const formData = new FormData(event.target);
        const statement = formData.getAll('statement');

        if (statement.length === 2) {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'Forces statement',
                eventAction: 'Both options chosen'
            });
        }

        if (statement.includes('armed-forces')) {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'Forces statement',
                eventAction: 'Member of forces'
            });
        }

        if (statement.includes('spouse-forces')) {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'Forces statement',
                eventAction: 'Spouse member of forces'
            });
        }
    });
};
