exports.contactAddress = function() {
    $('#contact-address-form').submit(function (event) {
        const formData = new FormData(event.target);
        const type = formData.get('type');
        if (type === "uk") {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'Contact address',
                eventAction: 'UK'
            });
        }
        if (type === 'bfpo') {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'Contact address',
                eventLabel: "BFPO"
            });
        }
        if (type === 'other') {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'Contact address',
                eventAction: 'Other'
            });
        }
    });
};
