exports.forcesService = function() {
    $('#forces-service-form').submit(function (event) {
        const formData = new FormData(event.target);
        const service = formData.get('service');
        if (service === "navy") {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'Forces service',
                eventAction: 'Navy'
            });
        }
        if (service === 'army') {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'Forces service',
                eventLabel: "Army"
            });
        }
        if (service === 'raf') {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'Forces service',
                eventAction: 'RAF'
            });
        }
    });
};
