exports.name = function() {
    $('#name-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventEverChangedName(formData);
    });
};

function gaEventEverChangedName(formData) {
    const nameChange = formData.get('name-change');
    if (nameChange === 'yes') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/name',
            eventAction: 'Name change',
            eventLabel: "Name has been changed"
        });
    }
    if (nameChange === 'no') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/name',
            eventAction: 'Name change',
            eventLabel: "Name has not been changed"
        });
    }
    if (nameChange === 'prefer-not-to-say') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/name',
            eventAction: 'Name change',
            eventLabel: "Prefer not to say"
        });
    }
}