exports.init = function() {
    window.registerToVote = {
        nationality: require('./nationality').nationality,
        nameChangePage: require('./name-change').nameChange,
        otherAddress: require('./other-address').otherAddress,
        previousAddress: require('./previous-address').previousAddress,
        openRegister: require('./open-register').openRegister,
        identityCard: require('./identity-card').identityCard,
        identityCardRequest: require('./identity-card-request').identityCardRequest,
        identityCardDelivery: require('./identity-card-delivery').identityCardDelivery,
        voteInPerson: require('./vote-in-person').voteInPerson,
        complete: require('./complete').complete,
        lastRegisteredToVote: require('./last-registered-to-vote').lastRegisteredToVote,
        waysToVote: require('./ways-to-vote').waysToVote,
        overseasVotingApplication: require('./overseas-voting-application').overseasVotingApplication,
        passport: require('./passport').passport,
        forcesStatement: require('./forces-statement').forcesStatement,
        addressFirstAnalytics: require('./address-first-analytics').addressFirstAnalytics,
        forcesService: require('./forces-service').forcesService,
        contactAddress: require('./contact-address').contactAddress,
        crownStatement: require('./crown-statement').crownStatement,
    };
};