exports.complete = function(journeyInfo) {
    $('.about-your-application-link').click(function () {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/complete',
            eventAction: "'About your application' clicked"
        });
    });

    $('.what-did-you-think-link').click(function () {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/complete',
            eventAction: "'What did you think' clicked"
        });
    });

    $('#apply-postal-vote').click(function () {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/complete',
            eventAction: `'Apply for postal vote' link clicked. Application type: ${journeyInfo.type}`
        });
    });

    $('#apply-proxy-vote').click(function () {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/complete',
            eventAction: `'Apply for proxy vote' link clicked. Application type: ${journeyInfo.type}`
        });
    });

    $('#find-out-more-postal-vote').click(function () {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/complete',
            eventAction: `'Find out more about postal voting' link clicked. Application type: ${journeyInfo.type}`
        });
    });

    $('#find-out-more-proxy-vote').click(function () {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/complete',
            eventAction: `'Find out more about proxy voting' link clicked. Application type: ${journeyInfo.type}`
        });
    });

    $('#check-photo-id-link').click(function () {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/complete',
            eventAction: `'Check what photo ID you need' link clicked. Application type: ${journeyInfo.type}`
        });
    });

    $('#voting-abroad-link').click(function () {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/complete',
            eventAction: `'Voting if you live abroad' link clicked. Application type: ${journeyInfo.type}`
        });
    });

    gaEventJourneyCompleted(journeyInfo);
};

function gaEventJourneyCompleted({type, isWelshLocale, isNI}) {
    const journeyDescription = isWelshLocale
        ? "Welsh journey"
        : (isNI ? 'NI ' : '')+type;

    ga && ga('send', {
        hitType: 'event',
        eventCategory: 'Journey complete',
        eventAction: journeyDescription
    });
}