exports.identityCard = function() {
    $('#identity-card-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventIdentityCard(formData);
    });
};

function gaEventIdentityCard(formData) {
    const identityCard = formData.get('postal-vote');
    if (identityCard === 'yes') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Has ID (NI)',
            eventAction: 'Yes',
        });
    }
    if (identityCard === 'no') {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: 'Has ID (NI)',
            eventAction: 'No',
        });
    }
}
