exports.openRegister = function() {
    $('#open-register-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventOpenRegisterOptOut(formData);
    });
};

function gaEventOpenRegisterOptOut(formData) {
    const openRegister = formData.get('open-register-opt-in');
    if (openRegister === "out") {
        ga && ga('send', {
            hitType: 'event',
            eventCategory: '/open-register',
            eventAction: 'Open register opt out',
        });
    }
}