exports.identityCardDelivery = function() {
    $('#identity-card-delivery-form').submit(function (event) {
        const formData = new FormData(event.target);
        const deliveryMethod = formData.get('delivery-method');
        if (deliveryMethod === "email") {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'EIC delivery method',
                eventAction: 'Email'
            });
        }
        if (deliveryMethod === 'post') {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: 'EIC delivery method',
                eventAction: 'Post'
            });
        }
    });
};