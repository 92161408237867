exports.overseasVotingApplication = function(voteType) {
    $('#overseas-voting-application-form').submit(function (event) {
        const formData = new FormData(event.target);
        gaEventOverseasVotingApplication(formData);
        gaEventDeliveryMethod(formData);
    });

    function gaEventOverseasVotingApplication(formData) {
        const formRequired = formData.get(`${voteType}-form`);
        if (formRequired === "yes") {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: `Overseas ${voteType} vote form required`,
                eventAction: 'Yes',
            });
        }

        if (formRequired === "no") {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: `Overseas ${voteType} vote form required`,
                eventAction: 'No',
            });
        }
    }

    function gaEventDeliveryMethod(formData) {
        const deliveryMethod = formData.get('delivery-method');
        if (deliveryMethod === "email") {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: `Overseas ${voteType} vote form delivery method`,
                eventAction: 'Email'
            });
        }
        if (deliveryMethod === 'post') {
            ga && ga('send', {
                hitType: 'event',
                eventCategory: `Overseas ${voteType} vote form delivery method`,
                eventAction: 'Post'
            });
        }
    }
};
